import React from "react";
import './MapKiosk.css';
import JatskikiskatLogo from './jatskikiskat-512.png';

class MapKiosk extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.kioskClicked = this.kioskClicked.bind(this);
    }

    kioskClicked() {
        this.props.setActiveKioskId(this.props.id);
    }

    render() {
        let popup = null;
        // let baseClassName = 'hover_box';
        if (this.props.activeKioskId === this.props.id) {
            // popup = <MapKioskPopup {...this.props}/>
            // baseClassName = 'hover_box_popop_open'
        }

        return (
            <div>
                <div className="kiosk_logo_ice_cream_container"
                     onClick={this.kioskClicked}>
                    {/*
                    <div className="kiosk_logo_ice_cream_shadow">
                        <FaIceCream/>
                    </div>
                    <div className="kiosk_logo_ice_cream">
                        <FaIceCream/>
                        <img src={JatskikiskatLogo} alt="Jätskikiskat.fi" />
                    </div>
                    */}
                    <div className="kiosk_logo_ice_cream">
                        <img src={JatskikiskatLogo} alt={this.props.kioskName} title={this.props.kioskName}/>
                    </div>
                </div>
                {/*
                <div className={baseClassName}
                     onClick={this.kioskClicked}>
                    <KioskBrandLogo {...this.props} />
                    <KioskName {...this.props} />
                </div>
                */}
                {popup}
            </div>
        )
    }
}

export default MapKiosk;
