import React from "react";
import './MainMap.css';
import GoogleMapReact from 'google-map-react';
import MapKiosk from './MapKiosk';
import MapKioskPopup from "./MapKioskPopup";
import AppHeader from "./AppHeader";
import ReactGA from "react-ga";

let mapLongPress: ReturnType<typeof setTimeout>;

const handleApiLoaded = (map: any, maps: any, mapComponent: MainMap) => {
    if (maps) {
        //maps.event.addDomListener(window, 'mouseup', (e:any) => {
        //    clearTimeout(mapLongPress);
        //});

//        maps.event.addListener(map, 'click', (event:any) => { console.log("kkk", ); });
        maps.event.addListener(map, 'mouseup', () => {
            clearTimeout(mapLongPress);
        });

        maps.event.addListener(map, 'drag', () => {
            clearTimeout(mapLongPress);
        });
        maps.event.addListener(map, 'zoom_changed', () => {
            clearTimeout(mapLongPress);
        });

        maps.event.addListener(map, 'mousedown', (e: any) => {
            const lat = e.latLng.lat();
            const lon = e.latLng.lng();

            mapLongPress = setTimeout(function () {
                mapComponent.setLongPressedLocation({latitude: lat, longitude: lon});
            }, 500);
        });
    }
};

class MainMap extends React.Component<any, any> {

    static defaultProps = {
        center: {
            lat: 60.16952,
            lng: 24.93545
        },
        zoom: 13
    };

    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            infoboxOpen: false,
            isLoaded: false,
            activeKioskId: null,
            longPressedLocation: null,
            lat: MainMap.defaultProps.center.lat,
            lng: MainMap.defaultProps.center.lng,
            zoom: MainMap.defaultProps.zoom,
            kiosks: []
        };

        this.setActiveKioskId = this.setActiveKioskId.bind(this);
        this.setLongPressedLocation = this.setLongPressedLocation.bind(this);
        this.mapClicked = this.mapClicked.bind(this);
        this.openInfobox = this.openInfobox.bind(this);
        this.closeInfobox = this.closeInfobox.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event: any) {
        if (event.keyCode === 27) {
            this.setLongPressedLocation(null);
        }
    }

    bindApiLoadedFunctions(map: any, maps: any) {
        handleApiLoaded(map, maps, this);
    }

    mapClicked() {
        if (this.state.activeKioskId !== "" && this.state.activeKioskId !== null) {
            this.setState({
                activeKioskId: null
            })
        }

        this.closeInfobox();
    }

    openInfobox() {
        this.setState({
            activeKioskId: null,
            infoboxOpen: true
        })
        ReactGA.event({
            category: 'User',
            action: 'Open info'
        });

    }

    closeInfobox() {
        this.setState({
            infoboxOpen: false
        })
    }

    setLongPressedLocation(location: any) {
        this.setState({
            longPressedLocation: location
        });
        ReactGA.event({
            category: 'User',
            action: 'Map long press',
            label: 'Lat ' + location.latitude + ' Lon ' + location.longitude,
            dimension1: location.latitude,
            dimension2: location.longitude
        });

    }

    setActiveKioskId(activeKioskId: string) {
        if (this.state.activeKioskId === activeKioskId) {
            this.setState({
                activeKioskId: null
            })
        } else {
            this.closeInfobox();
            this.setState({
                activeKioskId: activeKioskId
            })
            ReactGA.event({
                category: 'User',
                action: 'Open kiosk',
                label: activeKioskId
            });
        }
    }

    fetchLocationFromGeolocation() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.setState({lat: position.coords.latitude, lng: position.coords.longitude});
                },
                error => console.log(error)
            );
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

        this.fetchLocationFromGeolocation();

        fetch("/data/kiosks.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        kiosks: result.kiosks
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("Error!", error);
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {

        const {error, isLoaded, kiosks, activeKioskId, infoboxOpen} = this.state;
        if (error) {
            return <div>Error!</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            const kioskElements = kiosks.map((i: any, index: any) =>
                <MapKiosk activeKioskId={activeKioskId}
                          setActiveKioskId={this.setActiveKioskId}
                          key={i.id}
                          {...i}
                          loopIndex={index}/>);

            const kioskPopup = kiosks.filter(
                (kiosk: any) => kiosk.id === this.state.activeKioskId
            ).map((i: any, index: any) =>
                <MapKioskPopup activeKioskId={activeKioskId}
                               setActiveKioskId={this.setActiveKioskId}
                               key={i.id}
                               {...i}
                               loopIndex={index}/>);

            const lat = this.state.lat;
            const lng = this.state.lng;
            const zoom = this.state.zoom;

            return <div>
                <AppHeader infoboxOpen={infoboxOpen} openInfobox={this.openInfobox} closeInfobox={this.closeInfobox}/>
                <div className="mainmap">
                    <div style={{height: '100%', width: '100%'}}>
                        {/*
                    <div style={{height: '100%', width: '100%'}}>
                    <CreateNewKiosk longPressedLocation={longPressedLocation} longPressedLocationReset={this.setLongPressedLocation}/>
                    */}
                        {kioskPopup}
                        <GoogleMapReact
                            bootstrapURLKeys={{key: 'AIzaSyCCXFeFuKX8P9tPR1dIhOBBQEw2_JmSdJ0'}}
                            defaultCenter={this.props.center}
                            center={{lat, lng}}
                            defaultZoom={this.props.zoom}
                            zoom={zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map, maps}) => this.bindApiLoadedFunctions(map, maps)}
                            onClick={this.mapClicked}
                            options={{
                                clickableIcons: false,
                                fullscreenControl: false,
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                gestureHandling: 'greedy'
                            }}
                        >
                            {kioskElements}
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
        }
    }
}

export default MainMap;
