import React from "react";
import './KioskImage.css';

class KioskImage extends React.Component<any, any> {
    render() {
        return (
            <div>
                <img alt={this.props.title}
                     className='kiosk_image'
                     src={this.props.imageUrl}/>
            </div>

        )
    }
}

export default KioskImage
