import * as React from 'react';
import './App.css';
import MainMap from "./components/MainMap";
import ReactGA from 'react-ga';
import {useEffect} from "react";

function App() {

    useEffect(() => {
        ReactGA.initialize('UA-197921823-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="App">
            <MainMap/>
        </div>
    );
}

export default App;
