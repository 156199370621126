import React from "react";
import './KioskOnSale.css';

class KioskOnSale extends React.Component<any, any> {
    render() {
        if (this.props.onSale) {
            const onSaleItems = this.props.onSale.map((i: any, index: number) =>
                <KioskOnSaleItem key={index} {...i} />);

            return (
                <div>
                    <h3>
                        Myynnissä
                    </h3>
                    <ul className="on_sale_list">
                        {onSaleItems}
                    </ul>
                </div>
            )
        }
        return <></>
    }
}

class KioskOnSaleItem extends React.Component<any, any> {
    render() {
        return <li>{this.props.title} {this.props.price}</li>
    }
}

export default KioskOnSale
