import React from "react";
import './KioskPaymentOptions.css';

class KioskPaymentOptions extends React.Component<any, any> {
    render() {
        if (this.props.paymentOptions) {
            return (
                <div>
                    <h3>
                        Maksutavat
                    </h3>
                    <ul className="payment_options_list">
                        {this.props.paymentOptions.map((i: any, index: number) =>
                            <KioskPaymentOptionsItem key={index} {...i} />)}
                    </ul>
                </div>
            )
        }

        return <></>
    }
}

class KioskPaymentOptionsItem extends React.Component<any, any> {
    render() {
        return <li>{this.props.title}</li>
    }
}

export default KioskPaymentOptions
