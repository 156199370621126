import React from "react";
import './KioskStars.css';

class KioskStars extends React.Component<any, any> {
    render() {
        if (true) {
            return <></>
        }
        return (
            <div className="kiosk_stars">
                ★
                ★
                ★
                ★
                ★
            </div>
        )
    }
}

export default KioskStars
