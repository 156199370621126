import React from "react";
import './MapKiosk.css';
import JatskikiskatLogoPng from './jatskikiskat-512.png';

class JatskikiskatLogo extends React.Component<any, any> {

    render() {
        return (
            <div className="kiosk_logo_ice_cream">
                <img src={JatskikiskatLogoPng} alt="Jätskikiskat.fi"/>
            </div>
        )
    }
}

export default JatskikiskatLogo;
