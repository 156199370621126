import React from "react";

class KioskDescription extends React.Component<any, any> {
    render() {
        return (
            <div className="kiosk_description">
                {this.props.description}
            </div>
        )
    }
}

export default KioskDescription
