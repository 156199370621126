import * as React from 'react';
import './AppHeader.css';
import JatskikiskatLogo from './JatskikiskatLogo';

class AppHeader extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.headerClicked = this.headerClicked.bind(this);
    }

    headerClicked() {
        const isNowShown = this.state.infoShown;
        this.setState({
            infoShown: !isNowShown
        });
    }

    render() {
        let headerPopup = null;
        if (this.props.infoboxOpen) {
            headerPopup =
                <div className='info_box_container' onClick={this.props.closeInfobox}>
                    <div className='info_box'>
                        <JatskikiskatLogo />
                        <h1 className='header'>
                            Jätskikiskat.fi
                        </h1>
                        <p className='chapter'>
                            Jätskikiskat.fi näyttää sinulle Suomen jäätelökioskit.
                            Mikäli tiedoissa on puutteita tai virheitä,
                            laita meille sähköpostia info@jätskikiskat.fi.
                            Kehitämme palvelua jatkuvasti.
                        </p>
                        <p className='chapter'>
                            Maukasta kesää!
                        </p>
                    </div>
                </div>
        }

        return (
            <div>
                <header className="MainHeader">
                    <div className="row">
                        <div className="MainHeader__left column background-round"
                             onClick={this.props.openInfobox}>
                            <JatskikiskatLogo/>
                            <h1 className='logo-title'>Jätskikiskat.fi</h1>
                        </div>
                    </div>
                </header>
                {headerPopup}
            </div>
        );
    }
}

export default AppHeader;
