import React from "react";
import './KioskSocialMedia.css';
import {FaFacebook, FaInstagram} from "react-icons/all";

class KioskSocialMedia extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.openFacebook = this.openFacebook.bind(this);
        this.openInstagram = this.openInstagram.bind(this);
    }

    openFacebook() {
        window.open(this.props.socialMedia.facebookUrl, "_blank")
    }

    openInstagram() {
        window.open(this.props.socialMedia.instagramUrl, "_blank")
    }

    render() {
        if (!this.props.socialMedia) {
            return <></>
        }
        let facebook = null;
        if (this.props.socialMedia.facebookUrl) {
            facebook = <li className='facebook'>
                <FaFacebook onClick={this.openFacebook}/>
            </li>
        }
        let instagram = null;
        if (this.props.socialMedia.instagramUrl) {
            instagram = <li className='instagram'>
                <FaInstagram onClick={this.openInstagram}/>
            </li>
        }
        return (
            <div className='social_media_container'>
                <ul className='social_media_list'>
                    {facebook}
                    {instagram}
                </ul>
            </div>
        )
    }
}

export default KioskSocialMedia
