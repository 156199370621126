import React from "react";
import './KioskLogo.css';

class KioskOpenTimes extends React.Component<any, any> {
    render() {

        if (this.props.openHours) {
            const timeElements = this.props.openHours.map((i: any, index: number) =>
                <MapKioskPopupTimeSlot key={index} {...i} />);

            return (
                <div>
                    <h2>Aukioloajat</h2>
                    {timeElements}
                </div>
            )
        }

        return (
            <></>
        )
    }
}

class MapKioskPopupTimeSlot extends React.Component<any, any> {
    render() {
        return <div>
            {this.props.title}: {this.props.times}
        </div>;
    }

}


export default KioskOpenTimes
