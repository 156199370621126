import React from "react";
import './KioskName.css';

class KioskName extends React.Component<any, any> {
    render() {
        return (
            <div className='content'>
                <h2 className='kioskname'>
                    {this.props.kioskName}
                </h2>
            </div>
        )
    }
}

export default KioskName
