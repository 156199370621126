import React from "react";

class KioskAddress extends React.Component<any, any> {
    render() {
        return (
            <div>
                {this.props.addressString}
            </div>

        )
    }
}

export default KioskAddress
