import React from "react";
import './KioskBrandLogo.css';
import JatskikiskatLogo from '../jatskikiskat-512.png';

class KioskBrandLogo extends React.Component<any, any> {
    render() {
        if (this.props.brand) {
            return (
                <div className='logo_container content'>
                    <img alt={this.props.brand.name}
                         className='kiosk_logo'
                         src={this.props.brand.logoUrl}/>
                </div>
            )
        }
        return (
            <div className='logo_container content'>
                <img src={JatskikiskatLogo} alt="Jätskikiskat.fi" className="jatskikiskat"/>
            </div>
        )
    }

}

export default KioskBrandLogo
