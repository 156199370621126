import React from "react";
import './MapKioskPopup.css'
import KioskImage from "./kiosk/KioskImage";
import KioskStars from "./kiosk/KioskStars";
import KioskName from "./kiosk/KioskName";
import KioskDescription from "./kiosk/KioskDescription";
import KioskAddress from "./kiosk/KioskAddress";
import KioskOnSale from "./kiosk/KioskOnSale";
import KioskPaymentOptions from "./kiosk/KioskPaymentOptions";
import KioskOpenTimes from "./kiosk/KioskOpenTimes";
import KioskBrandLogo from "./kiosk/KioskBrandLogo";
import {FaWindowClose} from "react-icons/all";
import KioskSocialMedia from "./kiosk/KioskSocialMedia";

class MapKioskPopup extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.closePopup = this.closePopup.bind(this);
    }

    closePopup() {
        this.props.setActiveKioskId(null);
    }

    sendFeedbackEmail() {

    }


    render() {
        const styles = {
            container: {

            }
        };
        return (
            <div className='popup_box_container'>
                <div className='popup_box'>
                    <div className='header'>
                        <MapKioskPopupClose popupClose={this.closePopup}/>

                        <div className='header_container' style={styles.container}>
                            <KioskBrandLogo {...this.props} />

                            <KioskName {...this.props} />
                        </div>
                    </div>
                    <KioskImage {...this.props} />

                    <KioskDescription {...this.props} />

                    <KioskAddress {...this.props} />

                    <KioskSocialMedia {...this.props} />

                    <KioskOpenTimes {...this.props} />

                    <KioskStars {...this.props} />

                    <KioskOnSale {...this.props} />

                    <KioskPaymentOptions {...this.props} />

                    <div className='tailer'></div>
                </div>
            </div>
        );
    }
}

class MapKioskPopupClose extends React.Component<any, any> {

    render() {
        return (
            <div className="closewindow content">
                <FaWindowClose onClick={this.props.popupClose}/>
            </div>
        );
    }
}

export default MapKioskPopup;
